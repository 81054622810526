.node {
  width: 40px;
  height: 40px;
  border: 1px solid black;
}

.node-start {
  background-color: green;
}

.node-end {
  background-color: red;
}

.node-visited {
  animation-name: visitedAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes visitedAnimation {
  0% {
    transform: scale(0.3);
    background-color: rgba(0, 0, 66, 0.75);
    border-radius: 10%;
  }

  50% {
    background-color: magenta;
  }

  75% {
    transform: scale(1.2);
    background-color: magenta;
  }

  100% {
    transform: scale(1);
    background-color: magenta;
  }
}

.node-wall {
  background-color: rgb(12, 53, 71);
}

.node-shortest-path {
  animation-name: shortestPath;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes shortestPath {
  0% {
    transform: scale(0.6);
    background-color: cyan;
  }

  50% {
    transform: scale(1.2);
    background-color: cyan;
  }

  100% {
    transform: scale(1);
    background-color: cyan;
  }
}
